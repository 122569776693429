// This is webpack entry point
import React, { Suspense } from 'react';
import 'core-js/es7/object';
import { createRoot } from 'react-dom/client';
import envConfig from 'environmentConfig';
import { MemoryRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Router } from './components/App';
import { setUpWebHostAppIfAvailable } from './utils/webHostAppUtils';
import Loader from './components/Loader';
import './i18n';
import { getTheme } from './utils/theme';
import { AppWrapper } from './components/App/styled';

envConfig.initialize().then(async () => {
  setUpWebHostAppIfAvailable();
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <Suspense fallback={<Loader />}>
      <MemoryRouter>
        <ThemeProvider theme={createTheme(getTheme())}>
          <CssBaseline />
          <AppWrapper>
            <Router />
          </AppWrapper>
        </ThemeProvider>
      </MemoryRouter>
    </Suspense>
  );
});
