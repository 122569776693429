import React, { useEffect } from 'react';
import SharesViewHeader from './SharesViewHeader';
import ShareList from './ShareList';

import { ViewList } from '../App/styled';
import { DynatraceHelper } from '../../utils/dynatrace.helper';
import { pages as analyticsPages } from '../../constants/analyticsConfig';
import { SharesViewWrapper } from './styled';

/**
 *
 * @returns {JSX.Element}
 */
const SharesView = () => {
  useEffect(() => {
    DynatraceHelper.getInstance().setPage(analyticsPages.sharedViewsPage, analyticsPages.sharedViewsPage);
  }, []);

  return (
    <SharesViewWrapper>
      <SharesViewHeader />
      <ViewList>
        <ShareList />
      </ViewList>
    </SharesViewWrapper>
  );
};

export default SharesView;
