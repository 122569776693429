import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useTheme } from '@mui/material/styles';
import ActionItem from '../../models/ActionItem';
import Info from '../Info';
import Share from '../Share';
import host from '../../services/HostWrapper';
import shareService from '../../services/ShareService';
import useShallowStore from '../../hooks/useShallowStore';

import { ClickableShareWrapper, NoMatchFoundWrapper } from './styled';
import { noSearchResultImages, noSharesImages } from '../../img/missing';
import { componentEvents, launchDarklyMetrics } from '../../constants/analyticsConfig';
import adpInstance from '../../utils/adp';
import Loader from '../Loader';
import useCommentsUpdate from '../../hooks/useCommentsUpdate';
import useErrorHook from '../../hooks/useErrorHook';

/**
 *
 * @returns {JSX.Element}
 */
const ShareList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const updateComments = useCommentsUpdate();
  const { shares, searchText, isLoading, setMatchFound, setIsLoading, setHasFetchedShares, hasFetchedShares } =
    useShallowStore((state) => ({
      searchText: state.searchText,
      setMatchFound: state.setMatchFound,
      shares: state.shares,
      isLoading: state.isLoading,
      setIsLoading: state.setIsLoading,
      setHasFetchedShares: state.setHasFetchedShares,
      hasFetchedShares: state.hasFetchedShares,
    }));
  const filteredShares = searchText
    ? shares.filter((share) => share.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    : shares;
  const handleError = useErrorHook();
  const ldClient = useLDClient();

  useEffect(() => {
    if (hasFetchedShares) return;
    setIsLoading(true);
    shareService()
      .getShares()
      .then(() => {
        setHasFetchedShares(true);
        setIsLoading(false);
      })
      .catch(handleError);
  }, [setIsLoading, setHasFetchedShares, hasFetchedShares, handleError]);

  useEffect(() => {
    setMatchFound(filteredShares.length > 0);
  }, [setMatchFound, filteredShares]);

  if (isLoading) {
    return <Loader />;
  }

  if (filteredShares?.length > 0) {
    // return the shared view items
    return filteredShares.map((share) => (
      <ClickableShareWrapper
        key={share.viewerId}
        role="button"
        onClick={() => {
          updateComments(share);
          ldClient?.track(launchDarklyMetrics.CLICK_SHARE);
          adpInstance.trackComponentEvent(componentEvents.CLICK_SHARE);
        }}
        onKeyPress={(e) => e.key === 'Enter' && updateComments(share)}
        tabIndex="0"
        data-testid="clickable-share"
      >
        <Share share={share} />
      </ClickableShareWrapper>
    ));
  }

  return (
    <NoMatchFoundWrapper data-testid="no-shared-views">
      {searchText ? (
        // There is no search results
        <Info
          img={noSearchResultImages[theme.name]}
          title={t('search-no-result-title')}
          msg={t('search-no-result-message')}
        />
      ) : (
        // The user has no shared views
        <Info
          img={noSharesImages[theme.name]}
          title={t('share-none')}
          msg={t('share-none-message')}
          links={[new ActionItem(t('learn-shared-views'), host.launchHelp)]}
        />
      )}
    </NoMatchFoundWrapper>
  );
};

export default ShareList;
