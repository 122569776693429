import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DynatraceHelper } from '../utils/dynatrace.helper';
import useShallowStore from './useShallowStore';

const useErrorHook = () => {
  const navigate = useNavigate();
  const { setIsLoading, setErrorConfig } = useShallowStore((state) => ({
    setIsLoading: state.setIsLoading,
    setErrorConfig: state.setErrorConfig,
  }));
  return useCallback(
    (error) => {
      setIsLoading(false);
      if (error?.response) {
        const { response } = error;
        if ((response.status >= 500 && response.status < 600) || response.status === -1) {
          navigate('/app/servererror');
          return DynatraceHelper.getInstance().trackError(error);
        }
        if (response.status >= 400 && response.status < 500) {
          // Keep the config for retry
          setErrorConfig(response.config);
          navigate('/app/clienterror');
          return DynatraceHelper.getInstance().trackError(error);
        }
      }
      navigate('/unknownerror');
      return DynatraceHelper.getInstance().trackError(error);
    },
    [navigate, setIsLoading, setErrorConfig]
  );
};

export default useErrorHook;
