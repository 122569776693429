import envConfig from 'environmentConfig';

export class DynatraceHelper {
  static #instance;

  dynatraceInstance = null;

  releaseVersion = RELEASE_TAG;

  static #loadDynatraceScript() {
    return new Promise((resolve) => {
      const dtConfig = envConfig.get().dynatrace;
      const { app, beacon, baseUrl } = dtConfig;
      const src = `${baseUrl}/${beacon}/${app}_complete.js`;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.crossOrigin = 'anonymous';
      script.onload = () => resolve();
      script.onerror = () => resolve(); // Ignore the error if Dynatrace script fails to load
      document.head.insertBefore(script, document.head.firstChild);
    });
  }

  constructor() {
    DynatraceHelper.#loadDynatraceScript().then(() => {
      this.initializeDynatraceInstance();
    });
  }

  static getInstance() {
    if (!DynatraceHelper.#instance) {
      DynatraceHelper.#instance = new DynatraceHelper();
    }
    return DynatraceHelper.#instance;
  }

  initializeDynatraceInstance() {
    this.dynatraceInstance = window.dtrum;
    this.#enableManualPageDetection();
    this.#setReleaseVersion();
  }

  #enableManualPageDetection() {
    if (!this.dynatraceInstance) {
      return;
    }
    this.dynatraceInstance.enableManualPageDetection();
  }

  #setReleaseVersion() {
    if (!this.dynatraceInstance) {
      return;
    }
    this.dynatraceInstance.sendSessionProperties(null, null, { release: this.releaseVersion });
  }

  setPage(pageName, pageGroup) {
    if (!this.dynatraceInstance) {
      return;
    }
    this.dynatraceInstance.setPage({ name: pageName, group: pageGroup });
  }

  trackError(error) {
    if (!this.dynatraceInstance) {
      return;
    }
    if (error) {
      this.dynatraceInstance.reportError(error);
    } else {
      this.dynatraceInstance.reportCustomError('unknownerror', 'unknownerror');
    }
  }
}
