export const urlParams = {};

const search = /([^&=]+)=?([^&]*)/g;
const pl = /\+/g;
const query = window.location.search.substring(1);
const decode = (s) => decodeURIComponent(s.replace(pl, ' '));
let match = search.exec(query);

while (match !== null) {
  const urlParamName = decode(match[1]);
  const urlParamValue = decode(match[2]);
  urlParams[urlParamName] = decode(urlParamValue);
  match = search.exec(query);
}

export const getScheme = () => {
  return urlParams.scheme;
};

export const getCreateViewButtonStatus = () => {
  return urlParams.showCreate;
};

export const getSendAnalytics = () => {
  return urlParams.sendAnalytics;
};

export const getUploadFileFromPanel = () => {
  return urlParams.uploadFileFromPanel;
};
