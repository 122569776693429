import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Share from '../Share';
import ActionItem from '../../models/ActionItem';
import host from '../../services/HostWrapper';
import CommentList from './CommentList';
import { DynatraceHelper } from '../../utils/dynatrace.helper';
import { pages as analyticsPages, componentEvents, launchDarklyMetrics } from '../../constants/analyticsConfig';
import useShallowStore from '../../hooks/useShallowStore';
import useCurrentShare from '../../hooks/useCurrentShare';
import { ViewList } from '../App/styled';
import Loader from '../Loader';
import { BackButton, CommentsViewShareWrapper } from './styled';
import adpInstance from '../../utils/adp';

/**
 * CommentsView renders share with comments
 * @returns {JSX.Element | null}
 */

const CommentsView = () => {
  const currentShare = useCurrentShare();

  useEffect(() => {
    DynatraceHelper.getInstance().setPage(
      analyticsPages.commentsPage.replace(':id', currentShare.viewerId),
      analyticsPages.commentsPage
    );
  }, [currentShare]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const ldClient = useLDClient();

  const { showResolvedComments, setShowResolvedComments, isLoading } = useShallowStore((state) => ({
    showResolvedComments: state.showResolvedComments,
    setShowResolvedComments: state.setShowResolvedComments,
    isLoading: state.isLoading,
  }));

  if (!currentShare) return null;

  const handleShowResolvedClick = (e) => {
    e.preventDefault();
    setShowResolvedComments(!showResolvedComments);
  };

  const handleAddCommentClick = () => {
    ldClient?.track(launchDarklyMetrics.ADD_COMMENT_CLICK);
    adpInstance.trackComponentEvent(componentEvents.ADD_COMMENT_CLICK);
    host.launchBrowser(`${currentShare.a360link}?openComment=true`);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <BackButton onClick={() => navigate('/app')}>
        <ArrowBackIcon />
        <span>{t('comment-back')}</span>
      </BackButton>
      <CommentsViewShareWrapper data-testid="comments-view">
        <Share
          handleAddCommentClick={handleAddCommentClick}
          actionItems={[
            new ActionItem(
              (
                <FormControlLabel
                  control={<Checkbox data-testid="context-menu-checkbox" color="default" />}
                  label={t('comment-show-all-resolve')}
                  checked={showResolvedComments}
                />
              ),
              handleShowResolvedClick
            ),
          ]}
          share={currentShare}
        />
      </CommentsViewShareWrapper>
      <ViewList>
        <CommentList />
      </ViewList>
    </>
  );
};

export default CommentsView;
